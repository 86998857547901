<template>
    <div class="footer box-shadow" :style="`background-color:${bgcolor};bottom:${inputBottom}`">
      <van-row >
        <van-col offset="1" :span="send_msg.length>0 ? 17 : 22"  >
            <input 
              class="form-input" 
              type="text" 
              v-model="send_msg" 
              :onFocus="focus" 
              :onBlur="blur"
              maxlength="100"
              :style="`background-color:${inputcolor};`" 
            />
        </van-col>
        <template v-if="send_msg.length>0" >
          <van-col offset="1" span="5" >
            <div class="button" @click="send">{{common.getLanguage('send')}}</div>
          </van-col>
        </template>
      </van-row>
    </div>
</template>

<script>
import { reactive, toRefs,getCurrentInstance } from 'vue';
export default {
    name: 'footer_input',
    components: {
    },
    props: ['bgcolor','inputcolor','common','send'],
    setup(props){
        const { proxy } = getCurrentInstance();
        const common = proxy.$props.common;
        const state = reactive({
            send_msg:'',
            inputBottom:'0px',
            bgcolor:proxy.$props.bgcolor,
            inputcolor:proxy.$props.inputcolor,
        });
        
        const focus = function(e){
          state.keyHeight = e.detail.height;
          state.inputBottom = state.keyHeight + 'px';
          proxy.$emit("onfocus",state.keyHeight);
        }
        
        const blur = function(){
          state.inputBottom = "0px";
          proxy.$emit("onfocus",0);
        }

        const send = function(){
           proxy.$emit("send",state.send_msg);
           if(state.send_msg.length<100){
             state.send_msg = "";
           }
           
        }
        return {
            ...toRefs(state), 
            focus,
            blur,
            send,
            common
        }
  }
}
</script>

<style scoped>

.form-input{
    border-radius: 10px;
    height: 60px;
    line-height: 60px;
    border:0px;
    width:90%;
    padding:0 5%;
    font-size: 28px;
}
.footer{
  position: fixed;
  bottom:0;
  width: 100%;
  height:100px;
  text-align:center;
  padding-top: 10px;
  z-index:2;
  background-color: #F6F6F6 ;
}
input{
  color:#000;
}
.box-shadow{
  box-shadow: 0px 0px 20px #888888;
}

.button{
  background:#00C45E;
  border-radius:10px;
  width:80%;
  height: 70px;
  line-height: 70px;
  font-size: 36px;
}
</style>