<template>
    <div class="blessing" >
        <div class="nav">
            <van-row>
                <van-col span="4">
                    <div @click="back">
                        <van-icon name="arrow-left" color="#fff" />
                    </div>
                </van-col>
                <van-col span="16">
                    <text style="color:#fff">{{lang.leave_comments}}</text>
                </van-col>
                <van-col span="4">
                    <div @click="toggleLanguage">
                        <img class="lang-img" :src="lang.langBtn">
                    </div>
                </van-col>
            </van-row>
        </div>
        <div class="main">
            <div id="scrollview" ref="scroll">
                <template v-for="(msg,index) in msgList" :key="msg" :index="index">
                    <van-row :id="`index${index}`">
                        <van-col offset="1" span="22">
                            <div class="msg-row">
                                <van-row>
                                <van-col offset="1" span="3">
                                    <img class="head" :src="msg.avatar_url" />
                                </van-col>
                                <van-col offset="2" span="16">
                                    <div class="msg">
                                        {{msg.content}}
                                    </div>
                                </van-col>
                                </van-row>
                            </div>
                        </van-col>
                    </van-row>
                </template>
            </div>
        </div>
		<footerInput 
            bgcolor="#2A3F54" 
            :common="common" 
            inputcolor="#fff" 
            @send="send" 
            @onfocus="onfocus"></footerInput>
    </div>
</template>

<script>
import { reactive, toRefs , ref ,onMounted } from 'vue';
import Common from "../library/Common";
import footerInput from "../components/footer_input/index";
import myRequest from '../library/MyRequest';
import { Toast } from 'vant';
import { useRouter } from 'vue-router';
export default {
    name: 'blessing',
	components: {
		footerInput
	},
    setup(){
        const common = Common;
        const router = useRouter();
        const userInfo = common.getUserInfo();
        const program_id = router.currentRoute.value.params.id;

        var wxUserInfo = null;
        if(userInfo!=null){
            wxUserInfo = common.getWxUserInfo(userInfo);
        }else{
            router.push({name: 'login'});
        }
        if(wxUserInfo == null){
            router.back(-1);
        }

        const scroll = ref([]);
        let state = reactive({
            lang:{
                langBtn:"",
                leave_comments:"",
                language:"",
            },
            msgList:[],
            scrollHeight:"calc(100%)",
            scrollTop:0,
        });
        common.initLanguage(state.lang);
        state.lang.langBtn = common.getLanguageBtn();
        const toggleLanguage = function(){
            common.toggleLanguage();
            common.initLanguage(state.lang);
            state.lang.langBtn = common.getLanguageBtn();
        }

        const back = function(){
            router.back(-1);
        }

        const scrollAnimation = function(div,currentY, targetY){
            let needScrollTop = targetY - currentY;
            let _currentY = currentY
            setTimeout(() => {
                // 一次调用滑动帧数，每次调用会不一样
                const dist = Math.ceil(needScrollTop / 10)
                _currentY += dist
                div.scrollTo(_currentY, currentY)
                // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
                if (needScrollTop > 10 || needScrollTop < -10) {
                    scrollAnimation(div, _currentY, targetY)
                } else {
                    div.scrollTo(_currentY, targetY)
                }
            },100);
        }
        const toBottom = function(){
            var div = document.getElementById('scrollview');
            setTimeout(()=>{
                scrollAnimation(div,div.scrollTop,div.scrollHeight);
            },100);
        }

        const send = async(msg)=>{
            if(msg.length>100){
                Toast(common.getLanguage('length100'));
                return ;
            }
            var send = {
                avatar_url:wxUserInfo.avatar_url,//"https://thirdwx.qlogo.cn/mmopen/vi_32/LW2k6KW4rgiar5x9pyOEIrV1MYV5icnaiavFElFUeibtSOA1oTYHL4lHWuzAtmjZVibntmq73IJX4V1dPC2jtXicTtBQ/132",
                ticket_id:userInfo.ticket.id,
                program_id:program_id,
                content:msg,
            }
            await postMessage(send);      
        }

        const onfocus = function(data){
            state.scrollHeight = `calc(100% - ${data}rpx)`,
            state.scrollTop = state.scrollTop;
            toBottom();
        }
        
        const postMessage = async(message)=>{
            var res = await myRequest.post(`/activity/program/blessing/`,message);
            if(res.status == 201){
                state.msgList.push(message);
                toBottom();
            }
        }
        
        const getMessage = async()=>{
           var res = await myRequest.get(`/activity/program/blessing/?ticket_id=${userInfo.ticket.id}&&program_id=${program_id}`);
            if(res.status == 200){
                state.msgList = res.data;
            }
        }

        onMounted(()=>{
            state.lang.language = common.getCurrentLanguage();
            getMessage();
        })
        
        return {
            ...toRefs(state),
            common,
            toggleLanguage,
            back,
            onfocus,
            send,
            scroll
        };
    }
}
</script>

<style scoped>
.blessing{
    width: 100%;
    height: 100%;
    background-color: #2A3F54;
    color:#fff;
}
.nav{
    background-color: #2A3F54;
}
.main{
    width:100%;
    height:calc(100% - 110px - 100px);
    text-align: left;
    overflow-y:hidden;
}

#scrollview{
  width:100%;
  height:100%;
  overflow-y:auto;
}
.head{
  border-radius: 100%;
  height:100px;
}

.msg{
  margin-top:30px;
  font-size:32px;
  color:#fff;
  width:100%;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 2; /** 显示的行数 **/
  overflow: hidden;  /** 隐藏超出的内容 **/
}
.msg-row{
  border-radius:15px;
  border:1px solid #41515F;
  padding:15px 0 10px 0;
  margin-bottom: 20px;
  background-color: rgba(65,81,95,0.5);
}

</style>